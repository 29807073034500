/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./hamburgerStyle";

export function Hamburger({ opened, toggleMenu, scrolled, state }) {
  return (
    <button
      css={[style.button, scrolled && style.buttonScrolled]}
      type="button"
      aria-label="Menu"
      onClick={toggleMenu}
    >
      {/* <span css={style.hamburger}>
      <span css={[style.line1, opened && style.lineActive1]}></span>
      <span css={[style.line2, opened && style.lineActive2]}></span>
      <span css={[style.line3, opened && style.lineActive3]}></span>
    </span> */}

      <img
        src={state.isDarkMode ? "/assets/hamburger-white.png" : "/assets/hamburger-black.png"}
        width="100%"
        alt = 'logo'
      />
    </button>
  );
}
