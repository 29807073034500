import React from "react";

const DINOSAUR_TIMELINE_DATA = [
    {
        name: 'Hadean',
        startTime: 4597,
        endTime: 4032,
        eras: [],
    },
    {
        name: 'Archean',
        startTime: 4031,
        endTime: 2501,
        eras: [
            {
                name: 'Eoarchean',
                startTime: 4031,
                endTime: 3601,
                periods: [],
            },
            {
                name: 'Paleoarchean',
                startTime: 3600,
                endTime: 3201,
                periods: [],
            },
            {
                name: 'Mesoarchean',
                startTime: 3200,
                endTime: 2801,
                periods: [],
            },
            {
                name: 'Neoarchean',
                startTime: 2800,
                endTime: 2501,
                periods: [],
            },
        ]
  
    },
    {
        name: 'Proterozoic',
        startTime: 2500,
        endTime: 538.9,
        eras: [
            {
                name: 'Paleoproterozoic',
                startTime: 2500,
                endTime: 1601,
                periods: [
                    {
                        name: 'Siderian',
                        startTime: 2500,
                        endTime: 2301,
                    },
                    {
                        name: 'Rhyacian',
                        startTime: 2300,
                        endTime: 2051,
                    },
                    {
                        name: 'Orosirian',
                        startTime: 2050,
                        endTime: 1801,
                    },
                    {
                        name: 'Statherian',
                        startTime: 1800,
                        endTime: 1601,
                    },
                ],
            },
            {
                name: 'Mesoproterozoic',
                startTime: 1600,
                endTime: 1001,
                periods: [
                    {
                        name: 'Calymmian',
                        startTime: 1600,
                        endTime: 1401,
                    },
                    {
                        name: 'Ectasian',
                        startTime: 1400,
                        endTime: 1201,
                    },
                    {
                        name: 'Stenian',
                        startTime: 1200,
                        endTime: 1001,
                    },
                ],
            },
            {
                name: 'Neoproterozoic',
                startTime: 1000,
                endTime: 538.9,
                periods: [
                    {
                        name: 'Tonian',
                        startTime: 1000,
                        endTime: 721,
                    },
                    {
                        name: 'Cryogenian',
                        startTime: 720,
                        endTime: 636,
                    },
                    {
                        name: 'Ediacaran',
                        startTime: 635,
                        endTime: 538.9,
                    },
                ],
            },
        ]
    },
    {
        name: 'Phanerozoic',
        startTime: 538.8,
        endTime: 0,
        eras: [
            {
                name: 'Paleozoic',
                startTime: 538.8,
                endTime: 251.903,
                periods: [
                    {
                        name: 'Cambrian',
                        startTime: 538.8,
                        endTime: 485.5,
                    },
                    {
                        name: 'Ordovician',
                        startTime: 485.4,
                        endTime: 443.9,
                    },
                    {
                        name: 'Silurian',
                        startTime: 443.8,
                        endTime: 419.3,
                    },
                    {
                        name: 'Devonian',
                        startTime: 419.2,
                        endTime: 359,
                    },
                    {
                        name: 'Carboniferous',
                        startTime: 358.9,
                        endTime: 299,
                    },
                    {
                        name: 'Permian',
                        startTime: 298.9,
                        endTime: 251.903,
                    },
                ],
            },
            {
                name: 'Mesozoic',
                startTime: 251.902,
                endTime: 66.01,
                periods: [
                    {
                        name: 'Triassic',
                        startTime: 251.902,
                        endTime: 201.5,
                    },
                    {
                        name: 'Jurassic',
                        startTime: 201.4,
                        endTime: 145.1,
                    },
                    {
                        name: 'Cretaceous',
                        startTime: 145,
                        endTime: 66.01,
                    },
                ],
            },
            {
                name: 'Cenozoic',
                startTime: 66,
                endTime: 0,
                periods: [
                    {
                        name: 'Paleogene',
                        startTime: 66,
                        endTime: 23.04,
                    },
                    {
                        name: 'Neogene',
                        startTime: 23.03,
                        endTime: 2.59,
                    },
                    {
                        name: 'Quaternary',
                        startTime: 2.58,
                        endTime: 0,
                    },
                ],
            },
        ]
    }
  ];
    

  const findTimelineData = (name, data) => {
    for (const item of data) {
        if (item.name === name) return item;  // 匹配到直接返回数据
        if (item.eras && item.eras.length > 0) {
            const eraMatch = findTimelineData(name, item.eras);  // 递归搜索 eras
            if (eraMatch) return eraMatch;
        }
        if (item.periods && item.periods.length > 0) {
            const periodMatch = findTimelineData(name, item.periods);  // 递归搜索 periods
            if (periodMatch) return periodMatch;
        }
    }
    return null;  // 没有找到则返回 null

  };

  export { DINOSAUR_TIMELINE_DATA, findTimelineData };