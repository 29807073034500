import { mq } from "../../../../cssInJs";

export default {
  full__panel__google__map__embed: {},
  full__panel__google__map__embed__con: {
    maxWidth: "unset",
    paddingLeft: 0,
    paddingRight: 0,
    "& > div": mq({
      display: "flex",
      paddingBottom: ['58px', null, '93.5px']
    }),
    "iframe": mq({
      width: "100%",
      height: ["190px", null, "400px"],
    }),
  },
};
