import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { Contact } from "./contactForm";

export function ContactForm(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  const emailReceiver = cmsUtils.payload(item, "EmailReceiver");
  const fileAttachment = cmsUtils.payload(item, "FileAttachment") || "false";
  const mailchimpApiKey = cmsUtils.payload(item, "MailchimpApiKey");
  const mailchimpAudienceKey = cmsUtils.payload(item, "MailchimpAudienceKey");

  const cssClass = utils.classNames(
    "cms_item",
    "htmlPanel",
    cmsOption.isCmsEdit && "html",
    item.cssClass || item.anchorName || ""
  );

  return (
    <div
      className={cssClass}
      data-cms-html='true'
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <div className='container contact-wrapper' id='contactForm'>
        <Contact
          emailReceiver={emailReceiver}
          fileAttachment={fileAttachment}
          mailchimpApiKey={mailchimpApiKey}
          mailchimpAudienceKey={mailchimpAudienceKey}
        />
      </div>
    </div>
  );
}
