import { includes } from "lodash";
import { position } from "polished";
import React, { useEffect, useRef } from "react";
import './timebar.css';

export const CreateTimeBar = ({ name, startTime, endTime, backgroundColor, yearData }) => {
    console.log("Creating timeline with:", { name, startTime, endTime, backgroundColor, yearData });

    const TIMEBAR_EON = {

        fontWeight: '400',
    }

    const timebarRef = useRef(null);

    useEffect(() => {
        const timebar = timebarRef.current;
        if (!timebar) return;

        const timebarWidth = timebar.offsetWidth;

        function getLinePosition(year) {
            const timeDuration = startTime - endTime;
            const relativePosition = (startTime - year) / timeDuration;
            return relativePosition * timebarWidth;
        }

        function createVerticalLine(year) {
            const line = document.createElement("div");
            line.className = "vertical-line";
            line.style.position = "absolute";
            line.style.width = "2px";
            line.style.height = "100%";
            line.style.backgroundColor = "black";
            line.style.left = `${getLinePosition(year)}px`;
            line.style.top = "0";

            timebar.appendChild(line);
        }

        // 清除已有的垂直线，避免重复渲染
        timebar.querySelectorAll(".vertical-line").forEach(line => line.remove());

        // 创建新的垂直线
        yearData.forEach(year => createVerticalLine(year));

    }, [startTime, endTime, backgroundColor, yearData]);

    return (
        <React.Fragment>

            <div ref={timebarRef} style={{ backgroundColor: backgroundColor }} className="timebar-style" id="timebar">
                <div className="start-time">-- {startTime} Mya -</div>
                <div style={TIMEBAR_EON}>{name}</div>
                <div className="end-time">- {endTime} Mya --</div>
            </div>
            <div>

            </div>
        </React.Fragment>

    )
}