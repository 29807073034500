/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { Fragment } from "react";
import env from "../../env";
import { SiteLink } from "../../components";
import { usePost } from "../../components";
import { ErrorPopup, Loading } from "../../components";
import { ReactComponent as TileShapeSvg } from "./dinosaur-tile-shape.svg";
import { useRouter } from "../../components";
import utils from "../../utils";
import style from "./style";
import { useStore } from "store";

export function SearchResult(props) {
  const { query } = useRouter();
  const post = usePost();
  const keyword = query.q;

  React.useEffect(() => {
    post.send(env.apiBase + "/api/search/search", { keyword: keyword });
    // eslint-disable-next-line
  }, [keyword]);

  if (post.loading()) {
    return <Loading />;
  } else if (post.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={post.errors} />;
  }

  const data = post.response;
  const pages = data.pages;

  if (pages.length === 0)
    return (
      <Fragment>
        <h3 style={{ marginTop: "2rem" }}>
          Showing Search Results For: <span>{keyword}</span>
        </h3>
        <div className='alert alert-danger' role='alert'>
          No result found.
        </div>
      </Fragment>
    );
  return (
    <>
      <h3 style={{ marginTop: "2rem" }}>
        Showing Search Results For: <span>{keyword}</span>
      </h3>
      {pages.length > 0 && (
        <div>
          <PageList pages={pages} />
        </div>
      )}
    </>
  );
}

function PageList({ pages }) {
  return (
    <div className='pageTiles__small' css={style.pageTiles}>
      <div className='pageTiles_small_Warpper' css={style.pageTilesWarpper}>
        {pages.map((page, index) => (
          <Tile key={index} page={page}></Tile>
        ))}
      </div>
    </div>
  );
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const nightModeImageUrl = utils.site.resourcePath(page.nightModeImageUrl);
  const tileBottomText = page.tileBottomText;
  const disableLink = props.disableLink;
  const colorTheme =
    page.colorTheme && page.colorTheme === "default" ? "red" : page.colorTheme;
  const { state } = useStore();

  return (
    <div
      css={style.pageTile__tile}
      className={`pageTile__small ${!state.isDarkMode ? "light" : "dark"}`}
    >
      <div
        className={`tile__flip__wrapper ${
          !state.isDarkMode ? "light" : "dark"
        }`}
        css={style.tile__flip__wrapper}
      >
        <div
          className='pageTile__small__bg'
          data-aos='fade-up-right'
          css={style.tile__bg}
        >
          <SiteLink to={disableLink ? "" : page.pageUrl}>
            <div
              className='pageTile__small__bg2'
              css={style.tile__bg2}
              style={{
                backgroundImage: utils.css.bgUrlStyle(
                  nightModeImageUrl && state.isDarkMode
                    ? nightModeImageUrl
                    : imageUrl
                ),
              }}
            >
              {nightModeImageUrl && (
                <img
                  src={env.resourceBase + nightModeImageUrl}
                  width='1px'
                  height='1px'
                  style={{ visibility: "hidden" }}
                  alt='logo'
                />
              )}
            </div>
          </SiteLink>
        </div>
        <div
          css={style.tile__details}
          className={`pageTile__small__content__details ${colorTheme}`}
          data-aos='fade-up-left'
        >
          <SiteLink to={disableLink ? "" : page.pageUrl}>
            <TileShapeSvg />
            <div className='pageTile__small__container'>
              {page.pageTitle && page.pageTitle !== "" && (
                <div className='pageTile__small__title'>{page.pageTitle}</div>
              )}
              <div className='pageTile__small__desc'>
                {page.tileDesc || page.description}
              </div>
              <div className='pageTile__small__bottom'>{tileBottomText}</div>
            </div>
          </SiteLink>
        </div>
      </div>
    </div>
  );
}
