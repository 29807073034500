import { css } from "@emotion/core";
import { mq, mqMax, variables } from "../../cssInJs";

export default {
  top: css(
    mq({
      //height: [44, null, 80],
      //height: [44, null, 56],
      //display: "flex",
      //justifyContent: "center",
      //alignItems: "center",
      //transition: "all 0.3s",
      position: "fixed",
      width: "100%",
      zIndex: "999",
    })
  ),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(mq({})),
  logoImg: css(
    mq({
      display: "block",
      height: [22, null, 36],
    })
  ),
  logoScrolled: css(mq({})),

  day__night__button__bar: mq({
    position: "absolute",
    right: ["100px"],
    top: "50%",
    transform: "translateY(-50%)",
    display: ["block", null, null, "none"],
  }),

  search__button: mq({
    position: "absolute",
    right: ["10px", null, "50px"],
    top: "50%",
    transform: "translateY(-50%)",
    color: "white",
    fontSize: "0.75rem",
    fontWeight: variables.fontWeightSemiBold,
    cursor: "pointer",
    zIndex: "10",
    svg: {
      marginRight: "0.5rem",
    },
  }),

  top__navi__con__wrap: mq({
    background: "#370303",
    // padding: "5px 5px 2px",
    position: "relative",
    height: ["39px", null, "auto"],
  }),

  top__navi__con: mq({
    display: "flex",
    justifyContent: "center",
    visibility: "hidden",
    "@media (min-width: 1200px)": {
      visibility: "visible",
    },
    //maxWidth: '1021px',
    //margin: 'auto'
  }),

  top__navi__item: mq({
    color: "white",
    fontSize: "0.8rem",
    position: "relative",
    svg: {
      paddingBottom: "3px",
      marginRight: "10px",
    },
    a: {
      color: "inherit",
      display: "inline-block",
      padding: "0.4rem 0.5rem 11px",
      fontWeight: variables.fontWeightSemiBold,
      fontSize: "0.65rem",
      "&:hover": {
        textDecoration: "none",
        backgroundColor: variables.colorLightYellow,
      },
    },
  }),

  mast__head__svg__warpp: mq({
    position: "relative",
    "&:before": mq({
      content: "''",
      width: "50%",
      left: 0,
      top: 0,
      height: "74px",
      position: "absolute",
      backgroundColor: "white",
      display: ["none", null, "block"],
      zIndex: "-1",
      transition: "all 0.5s ease",
      transform: "rotate(1deg)",
      "@media (min-width: 2561px)": {
        //display: 'block',
        transform: "rotate(0deg)",
      },
    }),
    "&:after": mq({
      content: "''",
      width: "50%",
      right: 0,
      top: 0,
      height: "75%",
      position: "absolute",
      backgroundColor: "white",
      display: ["none", null, "block"],
      zIndex: "-1",
      transition: "all 0.5s ease",
      transform: "rotate(-1deg)",
      "@media (min-width: 2561px)": {
        //display: 'block',
        transform: "rotate(0deg)",
      },
    }),

    "&.dark": {
      "&:before, &:after": {
        backgroundColor: "black",
      },
    },
  }),

  mast__head__svg__warpp__scroll: mq({
    "&:before": {
      height: "54px",
      "@media (min-width: 2561px)": {
        //display: 'block',
        transform: "rotate(0deg)",
      },
    },
    "&:after": {
      //height: '65px',
      "@media (min-width: 2561px)": {
        transform: "rotate(0deg)",
      },
    },
  }),

  mast__head__svg__con: mq({
    maxWidth: "2560px",
    margin: "auto",
    height: "10000px",
    maxHeight: ["80px", null, "132px"],
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",
    backgroundPosition: "center bottom",
    position: "relative",
    transition: "all 0.5s ease",
    backgroundColor: ["white", null, "transparent"],
    clipPath: [
      "polygon(0 0, 0 60%, 60% 100%, 100% 65%, 100% 0%, 0 0)",
      null,
      "unset",
    ],

    "&.dark": {
      backgroundColor: "#000",
      backgroundImage: `url('/assets/masthead-shape-black.svg')`,
      // [mqMax.md]: {
      //   backgroundImage: "unset",
      // },
    },
    "&.light": {
      backgroundImage: `url('/assets/masthead-shape-white.svg')`,
      // [mqMax.md]: {
      //   backgroundImage: "unset",
      // },
    },
  }),

  mast__head__svg__con__scroll: mq({
    maxHeight: ["60px", null, "90px"],
  }),

  top__logo__img: mq({
    width: ["245px", null, "320px", "520px", "751px"], //'751px',
    margin: "auto",
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    transition: "all 0.5s ease",
  }),

  top__logo__img__scroll: mq({
    //width: '400px',
    width: ["200px", null, "500px"],
  }),

  home__toggle__contents__conv: mq({
    position: "absolute",
    right: "20px",
    height: "5px",
    top: "35%",
    width: ["20px", null, "186px"],
    //backgroundColor : 'red'
  }),
  home__toggle__contents__conv__scoll: mq({
    top: "30%",
  }),

  home__toggle__contents__con: mq({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }),
  home__toggle__contents__item: mq({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1.5rem",
    ".home__toggle-contents": {
      [mqMax.md]: {
        display: "none",
      },
    },
  }),

  popup__container: {
    backgroundColor: "rgba(0,0,0,0.8)",
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    zIndex: "9998",
  },

  popup__container__item: {
    position: "fixed",
    top: "20%",
    left: "50%",
    zIndex: "9999",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "540px",
  },
  top__autocomplete: {
    position: "relative",
    "& > div": {
      width: "100%",
    },
    "input": {
      "&:focus": {
        outline: "none",
      },
      border: "1px solid #cecece",
      padding: "12px 15px",
      fontSize: "16px",
      width: "100%",
    },
    ".top__autocomplete__dropdown": {
      // position: "absolute",
      width: "100%",
      left: "0px",
      top: "100%",
      textAlign: "left",
      maxHeight: "280px",
      overflow: "hidden",
      overflowY: "auto",
      backgroundColor: "#ffffff",
      border: "1px solid #cecece",
      borderTop: "none",
    },
    ".top__autocomplete__dropdown__item": {
      display: "block",
      cursor: "pointer",
      fontSize: "16px",
      padding: "15px",
      fontWeight: "600",
    },
  },
};
