import React, { useState, useEffect } from "react";
import env from "../../../env";
import { usePost } from "../../../components";
import { useForm } from "../../../form";
import { Button, ErrorMessage } from "../../../components";
import { getContactModel } from "./contactFormModel";
import { gtm } from "../../../lib/tracking";
import { enquiryTypeModel } from "./enquiryTypeModel";

export function Contact(props) {
  const emailReceiver = props.emailReceiver;
  const mailchimpApiKey = props.mailchimpApiKey;
  const mailchimpAudienceKey = props.mailchimpAudienceKey;

  const model = getContactModel({});
  const form = useForm(model, { usePlaceholder: true });
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [mailChimpError, setMailChimpError] = React.useState();
  const post = usePost();

  const mailChimpErrorMemo = React.useMemo(() => {
    return mailChimpError;
  }, [mailChimpError]);

  const [emailMismatchError, setEmailMismatchError] = useState(null);
  useEffect(() => {
    const formValue = form.getValue();
    if (
      formValue.email &&
      formValue.confirmEmail &&
      formValue.email !== formValue.confirmEmail
    ) {
      setEmailMismatchError("Email addresses do not match.");
    } else {
      setEmailMismatchError(null);
    }
  }, [form]);

  function onSubmit(e) {
    setMailChimpError(null);
    form.validateForm(e, () => {
      const formValue = form.getValue();
      if (formValue.email !== formValue.confirmEmail) {
        setEmailMismatchError("Email addresses do not match.");
        return;
      }
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(env.reCaptchaSiteKey, { action: "submit" })
          .then(function (token) {
            const selectedEmailReceiver =
              enquiryTypeModel[formValue.enquiryType] || emailReceiver;

            const value = {
              ...form.getValue(),
              emailReceiver: selectedEmailReceiver,
              mailchimpApiKey: mailchimpApiKey,
              mailchimpAudienceKey: mailchimpAudienceKey,
              grecaptchaToken: token,
            };

            var formData = new FormData();
            formData.append("jsonData", JSON.stringify(value));
            post.sendFormData(env.apiBase + "/api/contact/send", formData);
            setSendingStatus("pending");
          });
      });
    });
  }

  if (post.done() && sendingStatus === "pending") {
    var value = form.getValue();
    gtm.sendEvent("contactFormSubmitted", { contactEmail: value.email });
    form.reset(model);
    setSendingStatus(null);

    if (
      post.response.results &&
      post.response.results.MailChimpError &&
      post.response.results.MailChimpError !== ""
    ) {
      setMailChimpError(
        "Mail Chimp Error : " + post.response.results.MailChimpError
      );
    }
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <form className='contactForm'>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}>Name*</label>
          {render("name")}
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-6 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}>Your email*</label>
          {render("email")}
        </div>
        <div className='col-md-6 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}>Confirm your email*</label>
          {render("confirmEmail")}
          {emailMismatchError && (
            <div className='error-contact' role='alert'>
              {emailMismatchError}
            </div>
          )}
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}>
            What is your enquiry about?*
          </label>
          {render("enquiryType")}
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}>Email subject*</label>
          {render("subject")}
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}>Your message*</label>
          {render("message")}
        </div>
      </div>

      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <div className='actions'>
            <Button onClick={onSubmit} status={post.status}>
              Send Message
            </Button>
            <ErrorMessage
              errors={form.errors}
              summaryMessage='Please review the errors.'
            />
            <ErrorMessage errors={post.errors} />
            <ErrorMessage errors={mailChimpErrorMemo} />
            {post.done() && (
              <span
                style={{
                  marginLeft: "1.5rem",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Successfully sent!
              </span>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
