import { css } from "@emotion/core";
import { mq, variables } from "cssInJs";

export default {
  pageTiles: css({
    maxWidth: "1400px",
    marginLeft: "auto",
    marginRight: "auto",
  }),

  pageTilesWarpper: mq({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    overflow: "hidden",
    marginLeft: "-14.7px",
    marginRight: "-14.7px",

    "div.pageTiles__small.useSlider &": {
      display: "block",
      marginLeft: "0",
      marginRight: "0",
    },
  }),

  pageTile__tile: css({
    //flex: '0 0 100%',
    //maxWidth: '100%',
    //padding: "1rem",
    paddingLeft: "14.7px",
    paddingRight: "14.7px",
    paddingTop: "10px",
    paddingBottom: "10px",
    flex: "0 0 100%",
    maxWidth: "100%",

    "@media (min-width: 768px)": {
      flex: "0 0 50%",
      maxWidth: "50%",
    },

    "@media (min-width: 1400px)": {
      flex: "0 0 33.3%",
      maxWidth: "33.3%",
    },

    "div.pageTiles__small.useSlider &": {
      flex: "0 0 100%",
      maxWidth: "100%",
    },

    // "&:nth-of-type(even)": {
    //   ".pageTile__bsyc__bg": mq({
    //     order: ["0", null, "2"],
    //   }),
    //   ".pageTile__bsyc__content__details": mq({
    //     order: "1",
    //     marginLeft: "0rem",
    //     marginRight: "-2rem",
    //     zIndex: "1",
    //   }),
    //   ".tile__flip__wrapper": mq({
    //     justifyContent: "flex-end",
    //   }),
    // }
  }),

  tile__flip__wrapper: css({
    //zIndex:'-1',
    //display: "flex",
    //flexDirection: "row",
    //flexWrap: "wrap",
    // justifyContent: 'space-between',
    //width: '100%',
    //height: '100%',
    //cursor: "pointer",
    position: "relative",
    //justifyContent: "flex-start",
    //alignItems: "center",
    "&:has(.pageTile__small__bg2:hover), &:has(.pageTile__small__content__details:hover)":
      {
        "&.light .pageTile__small__content__details.red svg": {
          fill: variables.colorDarkRed,
        },
        "&.dark .pageTile__small__content__details.red svg": {
          fill: variables.colorLightRed,
        },

        "&.light .pageTile__small__content__details.green svg": {
          fill: variables.colorDarkGreen,
        },
        "&.dark .pageTile__small__content__details.green svg": {
          fill: variables.colorLightGreen,
        },

        "&.light .pageTile__small__content__details.teal svg": {
          fill: variables.colorDarkTeal,
        },
        "&.dark .pageTile__small__content__details.teal svg": {
          fill: variables.colorLightTeal,
        },

        "&.light .pageTile__small__content__details.yellow svg": {
          fill: variables.colorDarkyYellow,
        },
        "&.dark .pageTile__small__content__details.yellow svg": {
          fill: variables.colorLightYellow,
        },

        "&.light .pageTile__small__content__details.blue svg": {
          fill: variables.colorDarkBlue,
        },
        "&.dark .pageTile__small__content__details.blue svg": {
          fill: variables.colorLightBlue,
        },

        "&.light .pageTile__small__content__details.orange svg": {
          fill: variables.colorDarkOrange,
        },
        "&.dark .pageTile__small__content__details.orange svg": {
          fill: variables.colorLightOrange,
        },
      },
  }),

  tile__bg: css(
    mq({
      height: "0",
      paddingTop: ["56.25%"],
      position: "relative",
      //height: ['50%','35%','30%'],
      //flex: "1 1 auto",
      //maxWidth: "55%",
      //overflow: "hidden",
      //position: "relative",
    })
  ),

  tile__bg2: css(
    mq({
      height: "100%",
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      //overflow:'hidden',
      transition: "all .2s",
      position: "absolute",
      top: "0px",
      width: "100%",
      //clipPath: "polygon(0 0, 0 90%, 60% 100%, 100% 90%, 100% 0%, 0 0)",
    })
  ),

  tile__details: css({
    overflow: "hidden",
    //flexGrow: "1",
    //width: "100%",
    //bottom: "0",

    svg: {
      transition: "all .2s ease-in-out",
    },

    ".tile__flip__wrapper.light &.red svg": css({
      fill: variables.colorLightRed,
    }),
    ".tile__flip__wrapper.dark &.red svg": css({
      fill: variables.colorDarkRed,
    }),

    ".tile__flip__wrapper.light &.green svg": css({
      fill: variables.colorLightGreen,
    }),
    ".tile__flip__wrapper.dark &.green svg": css({
      fill: variables.colorDarkGreen,
    }),

    ".tile__flip__wrapper.light &.teal svg": css({
      fill: variables.colorLightTeal,
    }),
    ".tile__flip__wrapper.dark &.teal svg": css({
      fill: variables.colorDarkTeal,
    }),

    ".tile__flip__wrapper.light &.yellow svg": css({
      fill: variables.colorLightYellow,
    }),
    ".tile__flip__wrapper.dark &.yellow svg": css({
      fill: variables.colorDarkyYellow,
    }),

    ".tile__flip__wrapper.light &.blue svg": css({
      fill: variables.colorLightBlue,
    }),
    ".tile__flip__wrapper.dark &.blue svg": css({
      fill: variables.colorDarkBlue,
    }),

    ".tile__flip__wrapper.light &.orange svg": css({
      fill: variables.colorLightOrange,
    }),
    ".tile__flip__wrapper.dark &.orange svg": css({
      fill: variables.colorDarkOrange,
    }),

    //flex: "1 1 auto",
    transition: "all .2s ease-in-out",
    color: variables.fontColorWhite,
    position: "relative",
    //maxWidth: "33%",
    //marginLeft: "-2rem",
    a: {
      color: variables.fontColorWhite,
    },
    "& .pageTile__small__container": mq({
      position: ["absolute"],
      top: "50%",
      left: "50%",
      transform: ["translate(-50%, -50%)"],
      width: ["100%"],
      height: "100%",
      padding: ["1rem"],
      "& .pageTile__small__title": mq({
        fontSize: "2rem",
        maxWidth: "580px",
        lineHeight: "1",
        margin: "0",
        fontFamily: variables.familyHeader,
        color: "inherit",

        "@media (min-width: 768px)": {
          fontSize: "1.3rem",
        },

        "@media (min-width: 1400px)": {
          fontSize: "1.3rem",
        },
      }),
      "& .pageTile__small__desc": mq({
        lineHeight: "1.5",
        maxWidth: "580px",
        wordBreak: "break-word",
        color: "inherit",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: "4",
        textOverflow: "ellipsis",
        WebkitBoxOrient: "vertical",

        fontSize: "1rem",
        marginTop: "1rem",
        "@media (min-width: 768px)": {
          fontSize: "calc(100vw * 12 / 576)",
          marginTop: "0.5rem",
        },

        "@media (min-width: 1400px)": {
          fontSize: "0.8rem",
          marginTop: "0.5rem",
        },
      }),
      "& .pageTile__small__bottom": {
        maxWidth: "580px",
        fontSize: "1rem",
        marginTop: "1rem",
        fontWeight: variables.fontWeightBold,
        display: "inline-block",
        borderBottom: "1px solid white",
        //display: 'none',

        "@media (min-width: 768px)": {
          fontSize: "calc(100vw * 12 / 576)",
          marginTop: "0.5rem",
        },

        "@media (min-width: 1400px)": {
          fontSize: "0.8rem",
          marginTop: "0.5rem",
        },
      },
    }),
  }),
};
