import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";
import { useStore } from "../../../store";

export function SingleBanner(props) {
  
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const heightSet = cmsUtils.payload(item, "HeightSet") || "standard";
  const alignV = cmsUtils.payload(item, "AlignV") || "50%";
  const { state } = useStore();
  //const dayOrNight = cmsUtils.payload(item, "DayOrNight") || "day";
  
  const cssClass = utils.classNames(
    "cms_item",
    "single-banner",
    "banner-item",
    `bannerSize--${heightSet}`,
    item.cssClass || item.anchorName || ""
  );
  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));
  const bgUrl2 = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl2"));

  const captionTitle =
    cmsUtils.payload(item, "CaptionTitle2") ||
    cmsUtils.payload(item, "CaptionTitle");
  const linkUrl =
    cmsUtils.payload(item, "LinkUrl2") || cmsUtils.payload(item, "LinkUrl");

  const titlePanel = captionTitle ? (
    linkUrl ? (
      <SiteLink to={linkUrl} className='banner-item__link'>
        <div
          className='banner-item__title'
          dangerouslySetInnerHTML={{ __html: captionTitle }}
        ></div>
      </SiteLink>
    ) : (
      <div
        className='banner-item__title'
        dangerouslySetInnerHTML={{ __html: captionTitle }}
      ></div>
    )
  ) : null;

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      style={{
        backgroundImage: utils.css.bgUrlStyle( bgUrl2 && state.isDarkMode ? bgUrl2 : bgUrl),
        backgroundPositionY: alignV,
      }}
    >
      {titlePanel && <div className='banner_item__text'>{titlePanel}</div>}
    </div>
  );
}
