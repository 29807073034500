import React from "react";
import { IoSunnySharp } from "react-icons/io5";
import { IoMoon } from "react-icons/io5";
import { useStore } from '../../store';

export function DayNight() {
  
  const { state, dispatch } = useStore();
  const toggleTheme = () => {
    //setIsDarkMode(!isDarkMode);
    //document.body.setAttribute("data-theme", !isDarkMode ? "light" : "dark");
    const value = !state.isDarkMode;
    dispatch({ type: 'SET_STATE', payload: { isDarkMode : value } });
    document.body.classList.remove('light');
    document.body.classList.remove('dark');
    document.body.classList.add(!value ? "light" : "dark");
  };
  
  return (
    <div className="home__toggle-contents">
      {/* <div className='home__toggle-text'>
        {isDarkMode ? "Night Mode" : "Day Mode"}
      </div> */}
      <div className={`home__toggle-wrap ${!state.isDarkMode ? "light" : "dark"}`} onClick={toggleTheme}>
        <div
          className={`home__toggle-circle ${!state.isDarkMode ? "light" : "dark"}`}
        ></div>
        <IoSunnySharp size={"22"} style={{ color: "white", position: 'absolute', left: '5px', top: '50%', transform : 'translateY(-50%)' }} />
        <IoMoon size={"22"} style={{ color: "white", position: 'absolute', right : '5px', top: '50%', transform : 'translateY(-50%)' }} />
      </div>
    </div>
  );
}