import React from "react";
import { exportedBackgroundColor } from "../panelTimeline/view";

const FossilBackImg = () => {

    const style = `
    .fossil-back-img {
        position:absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

    }
    `

    return (
        // <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 900 900" className="fossil-back-img">
        //     <polygon points="597.33,160.88 597.33,738.38 303.33,738.38 303.33,193.38 497.33,160.88 " opacity="0.5" fill={color} />
        // </svg>
        <>
            <style>{style}</style>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 294 577.5" className="fossil-back-img">
                <polygon points="294,0 294,577.5 0,577.5 0,32.5 194,0" opacity="0.5" fill={exportedBackgroundColor} />
            </svg>
        </>

    )
}

export default FossilBackImg;