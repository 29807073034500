/** @jsx jsx */
import { jsx } from "@emotion/core";
import cmsUtils from "../../../cms/utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";
import { ReactComponent as TileShapeSvg } from "./dinosaur-tile-shape.svg";
import style from "./style";
import { useStore } from "../../../store";
import env from "env";
import Slider from "react-slick";

export function PageTilesSmall(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const useSlider = cmsUtils.payload(item, "UseSlider") === "slider";
  //const useSlider = false;
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  const pageTileElts = pages.map((page, index) => (
    <Tile
      page={page}
      key={index}
      disableLink={cmsOption.isCmsEdit}
      idx={index}
      useSlider={useSlider}
    ></Tile>
  ));

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles__small",
    useSlider && "useSlider",
    item.cssClass || item.anchorName || ""
  );

  if (useSlider) {
    return (
      <div
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        css={style.pageTiles}
      >
        <div className='pageTiles_small_Warpper' css={style.pageTilesWarpper}>
          <Slider {...settings}>{pageTileElts}</Slider>
        </div>
      </div>
    );
  }
  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.pageTiles}
    >
      <div className='pageTiles_small_Warpper' css={style.pageTilesWarpper}>
        {pageTileElts}
      </div>
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const nightModeImageUrl = utils.site.resourcePath(page.nightModeImageUrl);
  const tileBottomText = page.tileBottomText;
  const disableLink = props.disableLink;
  const useSlider = props.useSlider || false;
  const colorTheme =
    page.colorTheme && page.colorTheme === "default" ? "red" : page.colorTheme;
  //const index = props.idx;
  const { state } = useStore();

  return (
    <div
      css={style.pageTile__tile}
      className={`pageTile__small ${!state.isDarkMode ? "light" : "dark"}`}
    >
      <div
        className={`tile__flip__wrapper ${
          !state.isDarkMode ? "light" : "dark"
        }`}
        css={style.tile__flip__wrapper}
      >
        <div
          className='pageTile__small__bg'
          data-aos={!useSlider && "fade-up-right"}
          css={style.tile__bg}
        >
          <SiteLink to={disableLink ? "" : page.pageUrl}>
            <div
              className='pageTile__small__bg2'
              css={style.tile__bg2}
              style={{
                backgroundImage: utils.css.bgUrlStyle(
                  nightModeImageUrl && state.isDarkMode
                    ? nightModeImageUrl
                    : imageUrl
                ),
              }}
            >
              {nightModeImageUrl && (
                <img
                  src={env.resourceBase + nightModeImageUrl}
                  width='1px'
                  height='1px'
                  style={{ visibility: "hidden" }}
                  alt='logo'
                />
              )}
            </div>
          </SiteLink>
        </div>

        <div
          css={style.tile__details}
          className={`pageTile__small__content__details ${colorTheme}`}
          data-aos={!useSlider && "fade-up-left"}
        >
          <SiteLink to={disableLink ? "" : page.pageUrl}>
            <TileShapeSvg />
            <div className='pageTile__small__container'>
              {page.pageTitle && page.pageTitle !== "" && (
                <div className='pageTile__small__title'>{page.pageTitle}</div>
              )}
              <div className='pageTile__small__desc'>
                {page.tileDesc || page.description}
              </div>

              <div className='pageTile__small__bottom'>{tileBottomText}</div>
            </div>
          </SiteLink>
        </div>
      </div>
    </div>
  );
}
