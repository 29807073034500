/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { DayNight } from "./dayNight";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";
import { useStore } from "../../store";
import { NavBarHori } from "./navBarHori";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { state } = useStore();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }
  function onCloseMenu() {
    setMenuOpened(false);
  }

  return (
    <Fragment>
      <header
        className={`${scrolled && "scrolled"}`}
        css={[style.top, scrolled && style.topScrolled]}
        id='top'
      >
        <div
          className='top__navi__con__wrap'
          css={[style.top__navi__con__wrap]}
        >
          <div
            className='day__night__button__bar'
            css={style.day__night__button__bar}
          >
            <DayNightItem />
          </div>

          <div className='top__navi__con' css={[style.top__navi__con]}>
            <NavBarHori
              open={menuOpened}
              onCloseMenu={onCloseMenu}
            ></NavBarHori>
          </div>

          <BackButton />
        </div>
        <div
          className={`mast__head__svg__warpp ${
            state.isDarkMode ? "dark" : "light"
          }`}
          css={[
            style.mast__head__svg__warpp,
            scrolled && style.mast__head__svg__warpp__scroll,
          ]}
        >
          <div
            className={`mast__head__svg__con ${
              state.isDarkMode ? "dark" : "light"
            }`}
            css={[
              style.mast__head__svg__con,
              scrolled && style.mast__head__svg__con__scroll,
            ]}
          >
            <div
              className='top__logo__img'
              css={[
                style.top__logo__img,
                scrolled && style.top__logo__img__scroll,
              ]}
            >
              <SiteLink to='/'>
                <img
                  src={
                    state.isDarkMode
                      ? "/assets/masthead-title-white.png"
                      : "/assets/masthead-title-black.png"
                  }
                  alt='logo'
                  width='100%'
                />
              </SiteLink>
            </div>
          </div>

          <div
            className='home__toggle__contents__conv'
            css={[
              style.home__toggle__contents__conv,
              scrolled && style.home__toggle__contents__conv__scoll,
            ]}
          >
            <div
              style={{ width: "100%", height: "100%", position: "relative" }}
            >
              <div
                className='home__toggle__contents__con'
                css={[style.home__toggle__contents__con]}
              >
                <div
                  className='home__toggle__contents__item'
                  style={{ display: "flex" }}
                  css={style.home__toggle__contents__item}
                >
                  <DayNightItem />
                  <Hamburger
                    opened={menuOpened}
                    toggleMenu={toggleMenu}
                    scrolled={scrolled}
                    state={state}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
    </Fragment>
  );
}

const DayNightItem = React.memo(function DayNightItem() {
  return <DayNight />;
});
