const overloading = {
  breakpointNames: ["sm", "md", "lg", "xl", "xxl"],
  breakpoints: [576, 768, 992, 1200, 1600],
};

const colors = {
  colorBlue: "#007bff",

  colorDarkRed: "#370303",
  colorLightRed: "#983620",

  colorDarkGreen: "#63682f",
  colorLightGreen: "#909644",

  colorDarkTeal: "#37676b",
  colorLightTeal: "#4f9398",

  colorDarkyYellow: "#82683b",
  colorLightYellow: "#b99454",

  colorDarkBlue: "#275180",
  colorLightBlue: "#3873b6",

  colorDarkOrange: "#9f5027",
  colorLightOrange: "#e37238",
};

const variables = {
  familyBase: '"lato",sans-serif',
  familyHeader: '"trajan-pro-3",serif',
  primaryColor: colors.colorDarkRed,
  fontColor: "black",
  fontColorWhite: "white",
  bgColor: "#ebebeb",
  fontWeightNormal: 300,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  ...colors,
  ...overloading,
};

export default variables;
