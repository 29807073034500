import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";
import { useStore } from "../../../store";

export function BannerItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const { state } = useStore();

  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));
  const bgUrl2 = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl2"));
  const alignV = cmsUtils.payload(item, "AlignV") || "50%";
  const alignV2 = cmsUtils.payload(item, "AlignV2") || "50%";

  const captionTitle =
    cmsUtils.payload(item, "CaptionTitle2") ||
    cmsUtils.payload(item, "CaptionTitle");
  const captionContent = cmsUtils.payload(item, "CaptionContent") || "";
  const captionButton =
    cmsUtils.payload(item, "CaptionButton") &&
    cmsUtils.payload(item, "CaptionButton") !== ""
      ? cmsUtils.payload(item, "CaptionButton")
      : "READ MORE";

  const linkUrl =
    cmsUtils.payload(item, "LinkUrl2") || cmsUtils.payload(item, "LinkUrl");

  const titlePanel = captionTitle ? (
    linkUrl ? (
      <div className='banner-item__inside'>
        <div
          className='banner-item__title'
          dangerouslySetInnerHTML={{ __html: captionTitle }}
        ></div>
        {captionContent && (
          <div className='banner-item__content'>{captionContent}</div>
        )}
        <SiteLink to={linkUrl} className='banner-item__link'>
          {" "}
          {captionButton}{" "}
        </SiteLink>
      </div>
    ) : (
      <div
        className='banner-item__title'
        dangerouslySetInnerHTML={{ __html: captionTitle }}
      ></div>
    )
  ) : null;

  const linkOverlay =
    linkUrl && !captionTitle ? (
      <SiteLink className='bannerItem__overlay' to={linkUrl}></SiteLink>
    ) : null;

  const cssClass = utils.classNames(
    "cms_item",
    "banner-item",
    "bannerSize",
    item.cssClass || item.anchorName || "",
    titlePanel && "bannerHasTitlePanel"
  );

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      style={{
        backgroundPositionY: bgUrl2 && state.isDarkMode ? alignV2 : alignV,
        backgroundImage: utils.css.bgUrlStyle(
          bgUrl2 && state.isDarkMode ? bgUrl2 : bgUrl
        ),
      }}
    >
      {titlePanel && (
        <div className='banner_item__con'>
          <div className='banner_item__text'>{titlePanel}</div>
        </div>
      )}
      {linkOverlay && linkOverlay}
    </div>
  );
}
