//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from "@emotion/core";
import { mq, variables } from "../../../cssInJs";

export default {
  pageTiles: css(
    mq({
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-start',
      //flexWrap: "wrap",
      margin: "2rem 0px",
      overflow: "hidden",
      padding: ["0 15px", "0"],
    })
  ),
  pageTile__tile: css(
    mq({
      //flex: '0 0 100%',
      //maxWidth: '100%',
      padding: "1rem",
      paddingLeft: "0px",
      paddingRight: "0px",
      "&:nth-of-type(even)": {
        ".pageTile__bsyc__bg": mq({
          order: ["0", null, null, "2"],
        }),
        ".pageTile__bsyc__content__details": mq({
          order: "1",
          marginLeft: "0rem",
          marginRight: ["0rem", null, null, "-2rem"],
          zIndex: ["-1", null, null, "1"],
        }),
        ".tile__flip__wrapper": mq({
          justifyContent: "flex-end",
        }),
      },
    })
  ),

  tile__flip__wrapper: css({
    //zIndex:'-1',
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    // justifyContent: 'space-between',
    //width: '100%',
    //height: '100%',
    //cursor: "pointer",
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
    "&:has(.pageTile__bsyc__bg2:hover), &:has(.pageTile__bsyc__content__details:hover)":
      {
        "&.light .pageTile__bsyc__content__details.red svg": {
          fill: variables.colorDarkRed,
        },
        "&.dark .pageTile__bsyc__content__details.red svg": {
          fill: variables.colorLightRed,
        },

        "&.light .pageTile__bsyc__content__details.green svg": {
          fill: variables.colorDarkGreen,
        },
        "&.dark .pageTile__bsyc__content__details.green svg": {
          fill: variables.colorLightGreen,
        },

        "&.light .pageTile__bsyc__content__details.teal svg": {
          fill: variables.colorDarkTeal,
        },
        "&.dark .pageTile__bsyc__content__details.teal svg": {
          fill: variables.colorLightTeal,
        },

        "&.light .pageTile__bsyc__content__details.yellow svg": {
          fill: variables.colorDarkyYellow,
        },
        "&.dark .pageTile__bsyc__content__details.yellow svg": {
          fill: variables.colorLightYellow,
        },

        "&.light .pageTile__bsyc__content__details.blue svg": {
          fill: variables.colorDarkBlue,
        },
        "&.dark .pageTile__bsyc__content__details.blue svg": {
          fill: variables.colorLightBlue,
        },

        "&.light .pageTile__bsyc__content__details.orange svg": {
          fill: variables.colorDarkOrange,
        },
        "&.dark .pageTile__bsyc__content__details.orange svg": {
          fill: variables.colorLightOrange,
        },
      },
  }),

  tile__bg: css(
    mq({
      height: "0",
      paddingTop: ["56.25%", "35%", "30%"],
      //height: ['50%','35%','30%'],
      flex: ["1 1 100%", null, null, "1 1 auto"],
      maxWidth: ["100%", null, null, "55%"],

      //overflow: "hidden",
      //position: "relative",
    })
  ),

  tile__bg2: css(
    mq({
      height: "100%",
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      //overflow:'hidden',
      transition: "all .2s",
      position: "absolute",
      top: "0px",
      width: "100%",
      clipPath: "polygon(0 0, 0 90%, 60% 100%, 100% 90%, 100% 0%, 0 0)",
    })
  ),

  tile__details: css(
    mq({
      overflow: "hidden",
      flexGrow: "1",
      minHeight: ["256px", null, null, "unset"],
      paddingTop: ["3rem", null, null, "0rem"],
      marginTop: ["-3rem", null, null, "0rem"],
      zIndex: ["-1", null, null, "unset"],
      clipPath: [
        "polygon(0 0, 0 100%, 45% 100%, 100% 88%, 100% 0%, 0 0)",
        null,
        null,
        "unset",
      ],
      svg: mq({
        transition: "all .2s ease-in-out",
        display: ["none", null, null, "inline"],
      }),

      ".tile__flip__wrapper.light &.red svg": css({
        fill: variables.colorLightRed,
      }),
      ".tile__flip__wrapper.dark &.red svg": css({
        fill: variables.colorDarkRed,
      }),

      ".tile__flip__wrapper.light &.green svg": css({
        fill: variables.colorLightGreen,
      }),
      ".tile__flip__wrapper.dark &.green svg": css({
        fill: variables.colorDarkGreen,
      }),

      ".tile__flip__wrapper.light &.teal svg": css({
        fill: variables.colorLightTeal,
      }),
      ".tile__flip__wrapper.dark &.teal svg": css({
        fill: variables.colorDarkTeal,
      }),

      ".tile__flip__wrapper.light &.yellow svg": css({
        fill: variables.colorLightYellow,
      }),
      ".tile__flip__wrapper.dark &.yellow svg": css({
        fill: variables.colorDarkyYellow,
      }),

      ".tile__flip__wrapper.light &.blue svg": css({
        fill: variables.colorLightBlue,
      }),
      ".tile__flip__wrapper.dark &.blue svg": css({
        fill: variables.colorDarkBlue,
      }),

      ".tile__flip__wrapper.light &.orange svg": css({
        fill: variables.colorLightOrange,
      }),
      ".tile__flip__wrapper.dark &.orange svg": css({
        fill: variables.colorDarkOrange,
      }),

      ".tile__flip__wrapper.light &.red": {
        backgroundColor: variables.colorLightRed,
      },
      ".tile__flip__wrapper.dark &.red": {
        backgroundColor: variables.colorDarkRed,
      },

      ".tile__flip__wrapper.light &.green": {
        backgroundColor: variables.colorLightGreen,
      },
      ".tile__flip__wrapper.dark &.green": {
        backgroundColor: variables.colorDarkGreen,
      },

      ".tile__flip__wrapper.light &.teal": {
        backgroundColor: variables.colorLightTeal,
      },
      ".tile__flip__wrapper.dark &.teal": {
        backgroundColor: variables.colorDarkTeal,
      },

      ".tile__flip__wrapper.light &.yellow": {
        backgroundColor: variables.colorLightYellow,
      },
      ".tile__flip__wrapper.dark &.yellow": {
        backgroundColor: variables.colorDarkyYellow,
      },

      ".tile__flip__wrapper.light &.blue": {
        backgroundColor: variables.colorLightBlue,
      },
      ".tile__flip__wrapper.dark &.blue": {
        backgroundColor: variables.colorDarkBlue,
      },

      ".tile__flip__wrapper.light &.orange": {
        backgroundColor: variables.colorLightOrange,
      },
      ".tile__flip__wrapper.dark &.orange": {
        backgroundColor: variables.colorDarkOrange,
      },

      backgroundColor: ["red", null, null, "transparent !important"],

      transition: "all .2s ease-in-out",
      color: variables.fontColorWhite,
      position: "relative",
      flex: ["1 1 100%", null, null, "1 1 auto"],
      maxWidth: ["100%", null, null, "33%"],
      marginLeft: ["0rem", null, null, "-2rem"],
      a: {
        color: variables.fontColorWhite,
      },
      "& .pageTile__bsyc__container": mq({
        //position: 'absolute',
        //display: 'none',
        position: ["static", null, null, "absolute"],
        top: "50%",
        left: "50%",
        transform: ["none", null, null, "translate(-50%, -50%)"],
        width: ["100%", null, null, "100%"],
        height: "100%",
        padding: [
          "1rem 1rem 2.5rem 1rem",
          null,
          null,
          "2rem, 3rem",
          null,
          "3rem 4rem",
        ],
        "& .pageTile__bsyc__title": mq({
          fontSize: ["2rem", null, null, "calc(100vw * 16.5 / 768)"], //"2.3rem",
          maxWidth: "580px",
          lineHeight: "1",
          margin: "0",
          fontFamily: variables.familyHeader,
          color: "inherit",
        }),
        "& .pageTile__bsyc__desc": mq({
          lineHeight: "1.7",
          fontSize: ["1rem", null, null, "calc(100vw * 7.2 / 768)"], //"1rem",
          maxWidth: "580px",
          wordBreak: "break-word",
          marginTop: "1.2rem",
          color: "inherit",
        }),
        "& .pageTile__bsyc__bottom": mq({
          maxWidth: "580px",
          fontSize: ["1rem", null, null, "calc(100vw * 7.2 / 768)"], //"1rem",
          marginTop: "1.2rem",
          fontWeight: variables.fontWeightBold,
          display: "inline-block",
          borderBottom: "1px solid white",
        }),
      }),
    })
  ),
};
