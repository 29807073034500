import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";
import BottomDrawer from "../../components/bottomDrawer";
import { useStore } from "../../store";

export function BottomDock(props) {
  const links = [
    {
      url: "/visit",
      title: "VISIT",
      icon: "visit.png",
      iconHover: "visit-hover.png",
    },
    {
      url: "/discover",
      title: "DISCOVER",
      icon: "discover.png",
      iconHover: "discover-hover.png",
    },
    {
      url: "/learn",
      title: "LEARN",
      icon: "learn.png",
      iconHover: "learn-hover.png",
    },
    {
      url: "/experience",
      title: "EXPERIENCE",
      icon: "experience.png",
      iconHover: "experience-hover.png",
    },
    {
      url: "/support",
      title: "SUPPORT",
      icon: "support.png",
      iconHover: "support-hover.png",
    },
  ];

  //const path = usePath();
  const { pathname } = useRouter();
  const { state, dispatch } = useStore();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? "selected" : ""}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className='icon'>
          <img
            className='normal'
            src={`/assets/navicons/${link.icon}`}
            alt={link.title}
          />
          <img
            className='selected'
            src={`/assets/navicons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  const bottomMenu = useRef(null);
  useLayoutEffect(() => {
    const slider = new BottomDrawer(bottomMenu.current);
    setTimeout(() => {
      slider.init();
      if (
        pathname === "/hydro-smooth" ||
        pathname === "/digital-sensor-luxe" ||
        pathname === "/digital-sensor-luxe-test"
      ) {
        slider.close();
      }
    }, 1000);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!state.toggleBottomMenu) {
      return;
    }
    const slider = new BottomDrawer(bottomMenu.current);
    setTimeout(() => {
      slider.init();
      slider.close();
    }, 1000);
    dispatch({ type: "SET_STATE", payload: { toggleBottomMenu: null } });

    // eslint-disable-next-line
  }, [state.toggleBottomMenu]);

  return (
    <>
      <nav className='bottomMenu' ref={bottomMenu}>
        <div className='bottomMenu__bar'>
          <div className='bottomMenu__line1'></div>
          <div className='bottomMenu__line2'></div>
        </div>
        <ul className='bottomMenu__links'>{linkElts}</ul>
      </nav>
      <div className='bottomMenuPadding'></div>
    </>
  );
}
