/** @jsx jsx */
import { jsx } from "@emotion/core";
import cmsUtils from "../../../cms/utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";
import { ReactComponent as TileShapeSvg } from "./dinosaur-tile-shape.svg";
import style from "./style";
import { useStore } from "../../../store";
import env from 'env';

export function PageTilesBsyc(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  const pageTileElts = pages.map((page, index) => (
    <Tile
      page={page}
      key={index}
      disableLink={cmsOption.isCmsEdit}
      idx={index}
    ></Tile>
  ));

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles__bsyc",
    item.cssClass || item.anchorName || ""
  );

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.pageTiles}
    >
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const nightModeImageUrl = utils.site.resourcePath(page.nightModeImageUrl);
  const tileBottomText = page.tileBottomText;
  const disableLink = props.disableLink;
  const colorTheme =
    page.colorTheme && page.colorTheme === "default" ? "red" : page.colorTheme;
  const index = props.idx;
  const { state } = useStore();

  return (
    <div
      css={style.pageTile__tile}
      className={`pageTile__bsyc ${!state.isDarkMode ? "light" : "dark"}`}
    >
      <div className={`tile__flip__wrapper ${!state.isDarkMode ? "light" : "dark"}`} css={style.tile__flip__wrapper}>
        <div
          className="pageTile__bsyc__bg"
          data-aos={index % 2 === 0 ? "fade-up-right" : "fade-up-left"}
          css={style.tile__bg}
        >
          <SiteLink to={disableLink ? "" : page.pageUrl}>
            <div
              className="pageTile__bsyc__bg2"
              css={style.tile__bg2}
              style={{
                backgroundImage: utils.css.bgUrlStyle(
                  nightModeImageUrl && state.isDarkMode
                    ? nightModeImageUrl
                    : imageUrl
                ),
              }}
            >
              {
                nightModeImageUrl &&
                <img src = {env.resourceBase + nightModeImageUrl}  width = '1px'  height = '1px' style={{visibility: "hidden"}} alt = 'logo'/>
              }
            </div>
          </SiteLink>
        </div>

        <div
          css={style.tile__details}
          className={`pageTile__bsyc__content__details ${colorTheme}`}
          data-aos={index % 2 === 0 ? "fade-up-left" : "fade-up-right"}
        >
          <SiteLink to={disableLink ? "" : page.pageUrl}>
            <TileShapeSvg />
            <div className="pageTile__bsyc__container">
              
              {page.pageTitle && page.pageTitle !== "" && (
                <div className="pageTile__bsyc__title">{page.pageTitle}</div>
              )}
              <div className="pageTile__bsyc__desc">
                {page.tileDesc || page.description}
              </div>
              
              <div className="pageTile__bsyc__bottom">{tileBottomText}</div>

              {/* <div className="pageTile__bsyc__title">
                DISCOVER
              </div> */}
              {/* <div className="pageTile__bsyc__desc">
                The Australian Age of Dinosaurs Museum is home to the largest
                collection of Australian dinosaur fossils in the world. The
                Museum is split between three facilities, the Fossil Preparation
                Laboratory, Collection Room and Dinosaur Canyon.
              </div> */}
              {/* <div className="pageTile__bsyc__bottom">
                PLAN YOUR VISIT {">"}
              </div> */}
            </div>
          </SiteLink>
        </div>
      </div>
    </div>
  );
}
